:root {
  // --app-bg-color: #F9FAF5;
  // --app-bg-color: #FFF;
  --app-bg-color: #FAFAEB;
  --app-bg-color: #F9F5F1;
  --app-bg-color: #fffff8;
  --app-bg-color: white;
  --text-color: #f1d3b3;
  --btn-width: 120px;
  --btn-height: 40px;
  --block-padding: 60px;

  --app-line-color: red; //dadce0
  --app-line-color: #000; //dadce0
  --app-line-color: #eee; //dadce0
  --app-card-selected-border-color: #000; //dadce0

  // Template variables
  --black: #000000;
  --grey: #999999;
  --bold: 800;

  --primary: #0075FF;
  --primary: #14181F;
  --primary: #000;

  // Buttons
  --btn-bg-grey: #eee;
  --btn-bg-grey-hover: #ccc;
}
