@import-normalize;
@import "./styles/_helpers";
@import "./styles/_mixins";
@import "./styles/_variables";
@import "./styles/_buttons";
@import "./styles/_radiobuttons";
@import "./styles/_texts";
@import "./styles/_margins";
@import "./styles/_fonts";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Mntsrt", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  * {
    box-sizing: border-box;
  }
}

/* links */
a:link, a:visited, a:hover, a:active {
  color: var(--primary);
}