$primary: #FF8A1E;
$gradientColor01: rgba(255,162,0,1);
$gradientColor02: rgba(255,109,67,1);
$bgColorTemplate: #F5F7F9;
$bgColorWrapper: #FFFFFF;
.template-07 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    position: relative;
    .bg-top {
      background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
      width: 100%;
      height: 25%;
    }
    .bg-bottom {
      height: 75%;
      width: 100%;
    }
    .wrapper {
      position: absolute;
      width: calc(100% - 60px);
      height: calc(100% - 96px);
      padding: 60px 56px 0;
      background-color: $bgColorWrapper;
      border-radius: 20px;
      .group {
          display: flex;
          align-items: center;
          gap: 32px;
          .badge {
              width: 54px;
              height: 54px;
              border-radius: 50%;
              background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
              display: flex;
              align-items: center;
              justify-content: center;
          }
        }
        .description {
            line-height: 1.625;
        }
    }
    .primary {
        color: $primary;
    }
}