$primary: #FF8A1E;
$gradientColor01: rgba(255,162,0,1);
$gradientColor02: rgba(255,109,67,1);
$bgColorTemplate: #FFFFFF;
$bgColorWrapper: red;
$bgColorInnerWrapper: #FFFFFF;
.template-03 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    .wrapper {
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        background-color: $bgColorWrapper;
        border-radius: 40px 80px;
        background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
        .inner-wrapper {
          padding: 60px 56px 0;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
          border-radius: 32px 72px;
          background-color: $bgColorInnerWrapper;
          .group {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .badge {
                  width: 106px;
                  height: 106px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
          }
        }
        .description {
            line-height: 1.625;
        }
    }
    .primary {
        color: $primary;
    }
}