.sidebar {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  &__header {
    font-size: 14px;
    padding: 24px 24px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button span {
      font-size: 18px;
      line-height: 24px;
    }
  }
  &__main {
    overflow-y: auto;
    .form-group {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      .title {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 700;
        color: #999;
        height: 48px;
        display: flex;
        align-items: center;
      }
      .subtitle {
        font-size: 1.4rem;
        margin: 0;
        font-weight: 600;
        &:not(:first-of-type) {
          margin-top: 1.6rem;
        }
        margin-bottom: 1.6rem;
      }
    }
    .form-group-data {
      div:nth-child(2) {
        margin-top: -16px;
      }
    }
    input[type="radio"] + label {
      margin-right: 24px;
    }
    input[type="text"],
    textarea {
      width: 100%;
      overflow: hidden;
      font-weight: 400;
      color: #333;
      background-color: #fff;
      border: 1px solid #dadada;
      border-radius: 4px;
      outline: none;
      padding: 9px 12px;
      &::selection {
        background: #ccc;
      }
      &:focus {
        border:2px solid var(--primary);
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: end;
    gap: 12px;
    button {
      width: 50%;
    }
  }
}