.card {
    .card__cover {
        background-color: lightgrey;
        position: relative;
        z-index: -1;
        border: 1px solid #dadce0;
        box-shadow: 0 0 0 0 rgba(60,64,67,.11), 0 2px 6px 2px rgba(60,64,67,.15);
        cursor: pointer;
        background-size: contain;
    }
    .card__cover:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(21/29.7 * 100%);
    }
    .card__footer {
        display: flex;
        align-items: center;
        padding: 12px 4px 12px 0;
        .logo {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }
        .template-label {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        .author-name {
            font-size: 12px;
        }
        .likes-icon {
            margin-left: auto;
        }
        .likes-label, .views-label {
            margin-left: 4px;
        }
        .likes-label, .views-label {
            color: #909090;
        }
        .likes-icon svg, .views-icon svg{
            fill: #909090;
        }
        .views-icon {
            margin-left: 8px;
        }
    }
}