.bold {
  font-family: 700;
}

.fx-col-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uppercase {
  text-transform: uppercase;
}

/* Type Scale styles (1.333 - Perfect Fourth) */
.scale-perfectFourth {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 4.8rem 0 2.208rem;
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    margin-top: 0;
    font-size: 6.7344rem;
  }

  h2 {
    font-size: 5.0512rem;
  }

  h3 {
    font-size: 3.7904rem;
  }

  h4 {
    font-size: 2.8432rem;
  }

  h5 {
    font-size: 2.128rem;
  }

  p {
    font-size: 1.6rem;
  }

  small,
  .text_small {
    font-size: 1.2rem;
  }
}

// TODO - REVIEW ABOVE
.fx-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fx-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
