$layout-max-width: 1280px;
.container {
    width: 100%;
    max-width: $layout-max-width;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
}

.info-header {
    margin: 36px 0 24px;
    div {
        font-size: 2.8rem;
        font-weight: 600;
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 48px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    .template-area {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        z-index: -1;
        @media screen and (min-width: 768px) {
            width: calc(100% - 400px);
        }
    }
    .sidebar {
        max-width: 320px;
        min-width: 320px;
        // @media screen and (min-width: 768px) {
        //     width: 400px;
        //     min-width: 400px;
        // }
    }
}


.template-canvas {
    background-color: blue;
    margin: auto;
}

.template-wrapper {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
}

.template-info {
    margin-top: 2.4rem;
    font-size: 1.6rem;line-height: 1.75;
    p:first-child {
        font-weight: 700;
    }
}
