$primary: #F2BE5B;
$gradientColor01: #F2BE5B;
$gradientColor02: #F8D590;
$bgColorTemplate: #FFFFFF;
$bgColorWrapper: red;
$bgColorInnerWrapper: #FFFFFF;
.template-13 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    .wrapper {
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        background-color: $bgColorWrapper;
        border-radius: 40px;
        background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
        .inner-wrapper {
          padding: 96px 36px 24px 0;
          width: calc(100% - 36px);
          height: calc(100% - 36px);
          border-radius: 22px;
          background-color: $bgColorInnerWrapper;
          display: flex;
          justify-content: space-between;
          > div:not(:first-of-type) {
            width: 70%;
            float: right;
          }
          .badge-container {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              .badge-border {
                width: 172px;
                height: 172px;
                border-radius: 50%;
                border: 10px dashed $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: normal;
              }
              .badge {
                  width: 120px;
                  height: 120px;
                  border-radius: 50%;
                  background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
          }
          .group {

          }
        }
        .description {
            line-height: 1.625;
        }
    }
    .primary {
        color: $primary;
    }
}