.txt-16 {
    font-size: 16px;
}
.txt-18 {
    font-size: 18px;
}
.txt-20 {
    font-size: 20px;
}
.txt-32 {
    font-size: 32px;
}
.txt-40 {
    font-size: 40px;
}
.txt-52 {
    font-size: 52px;
}
.txt-56 {
    font-size: 56px;
}

.txt-uppercase {
    text-transform: uppercase;
}

.txt-italic {
    font-style: italic;
}

.txt-align-center {
    text-align: center;
}
.txt-align-left {
    text-align: left;
}
.txt-align-right {
    text-align: right;
}

.txt-color-black {
    color: black;
}
.txt-color-white {
    color: white;
}
.txt-color-999 {
    color: #999999;
}

.txt-300 {
    font-weight: 300;
}
.txt-400 {
    font-weight: 400;
}
.txt-500 {
    font-weight: 500;
}
.txt-600 {
    font-weight: 600;
}
.txt-700 {
    font-weight: 700;
}
.txt-800 {
    font-weight: 800;
}
.txt-900 {
    font-weight: 900;
}
