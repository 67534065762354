button {
  font-family: "Mntsrt";
  height: 36px;
  background: none;
  border: none;
  color: inherit;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  outline: inherit;
  &.btn-text {
    border: none;
  }
  &.btn-outlined {
    border: 2px solid var(--primary);
    background: none;
    color: var(--primary);
  }
  &.btn-basic {
    background: var(--btn-bg-grey);
    color: var(--primary);
    font-weight: 700 !important;
    &:hover {
      background-color: var(--btn-bg-grey-hover);
    }
  }
  &.btn-solid {
    color: white;
    background-color: var(--primary);
  }
}