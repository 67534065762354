.margin-bottom-4 {
    margin-bottom: 4px;
}
.margin-bottom-8 {
    margin-bottom: 8px;
}
.margin-bottom-16 {
    margin-bottom: 16px;
}
.margin-bottom-18 {
    margin-bottom: 18px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-24 {
    margin-bottom: 24px;
}
.margin-bottom-28 {
    margin-bottom: 28px;
}
.margin-bottom-32 {
    margin-bottom: 32px;
}
.margin-bottom-36 {
    margin-bottom: 36px;
}
.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-bottom-44 {
    margin-bottom: 44px;
}
.margin-bottom-48 {
    margin-bottom: 48px;
}
.margin-bottom-52 {
    margin-bottom: 52px;
}
.margin-bottom-56 {
    margin-bottom: 56px;
}
.margin-bottom-60 {
    margin-bottom: 60px;
}
.margin-bottom-72 {
    margin-bottom: 72px;
}
.margin-bottom-160 {
    margin-bottom: 160px;
}