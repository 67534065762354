$primary: #F75981;
$gradientColor01: #FB9C98;
$gradientColor02: #F75981;
$bgColorTemplate: #FFFFFF;
.template-10 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    position: relative;
    .bg-left {
      height: 100%;
      width: 80px;
      background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
      position: absolute;
      z-index: 1;
      left: calc(15%);
    }
    .badge {
      position: absolute;
      background-color: #FFFFFF;
      top: calc(66% - 84px);
      left: calc(15% - 40px);
      z-index: 1;
      margin: 4px 0;
      padding: 8px 0;
      svg path {
        fill: $primary;
      }
    }
    .wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 80px 56px 0;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: end;
      .group {
          display: flex;
          flex-direction: column;
          align-items: end;
          div:last-child {
            font-size: 38.5px;
          }
      }
      .description {
          line-height: 1.625;
          max-height: 98px;
          overflow: hidden;
      }
      .course {
        max-height: 92px;
        overflow: hidden;
      }
      .name {
        max-height: 60px;
        overflow: hidden;
        padding-right: 20px;
      }
      .name, .course, .description {
        max-width: 75%;
      }
      .date{
        background-color: $primary;
        padding: 10px;
      }
    }
    .primary {
        color: $primary;
    }
}