$layout-max-width: 1280px;
.diplooo-app {
  display: flex;
  flex-direction: column;
  .diplooo-app__navbar {
    background-color: white;
    width: 100%;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    .wrapper {
      padding: 18px 24px;
      position: relative;
      max-width: $layout-max-width;
      margin: 0 auto;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
      }
      .imagotype {
        display: flex;
        align-items: center;
        .logo {
          background-image: url('./assets/logo.png');
          background-size: cover;
          height: 32px;
          width: 32px;
        }
        .title {
          display: block;
          font-size: 3.2rem;
          font-weight: 800;
          margin-left: 8px;
          .label-version {
            color: rgba(126,98,235,.69);
            font-size: 0.9rem;
            font-weight: 700;
            position: absolute;
            left: 120px;
            text-transform: uppercase;
            top: 50px;
          }
        }
      }
      .actions {
        margin-left: auto;
        .btn-text {
          span:first-child {
            display: none;
          }
          @media screen and (min-width: 768px) {
            span:first-child {
              display:block;
            }
            span:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
  .diplooo-app__main {
    height: calc(100vh - 72.8px);
    overflow: auto;
  }
  .diplooo-app__content {
  }

}