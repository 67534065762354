$primary: #828195;
$gradientColor01: #DAE5E7;
$gradientColor02: #828195;
$bgColorTemplate: #FFFFFF;
.template-14 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    position: relative;
    .layer-1 {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      .left, .right {
        height: 100%;
      }
      .left {
        width: 33%;
        background: none;
        .column {
          width: 80%;
          height: 33%;
          background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
          margin-left: 80px;
          .badge {
            height: 100%;
          }
        }
      }
      .right {
        width: 67%;
        background:none
      }
    }
    .layer-2 {
      width: 100%;
      height: 100%;
      .top, .bottom {
        width: 100%;
      }
      .top {
        height: 33%;
        background: none;
        > div {
          width: 67%;
          margin-left: 33%;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: right;
          padding-right: 80px;
          height: 100%;
          span {
            border-bottom: 6px solid $primary;
          }
        }
      }
      .bottom {
        height: 67%;
        background-color: $bgColorTemplate;
        .wrapper {
          // margin-left: 33%;
          padding: 40px 80px 0;
          .course {
            max-height: 92px;
            overflow: hidden;
          }
          .name {
            max-height: 120px;
            overflow: hidden;
            padding-right: 20px;
          }
        }
      }
    }
}