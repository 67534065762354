// Montserrat
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-Light.ttf);
    font-weight:300;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf);
    font-weight:400;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-Medium.ttf);
    font-weight:500;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf);
    font-weight:600;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-Bold.ttf);
    font-weight:700;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf);
    font-weight:800;
}
@font-face {
    font-family: Mntsrt;
    src: url(../assets/fonts/Montserrat/Montserrat-Black.ttf);
    font-weight:900;
}