$primary: #032941;
$gradientColor01: #A6C1FE;
$gradientColor02: #00263E;
$bgColorTemplate: #F5F7F9;
$bgColorWrapper: #FFFFFF;
.template-09 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    position: relative;
    .bg-top-left {
      display: inline-block;
      width: 200px;
      height: 200px;
      background-color: $gradientColor01;
      position: absolute;
      top: 0;
      left: 0;
    }
    .bg-bottom-left {
      width: 200px;
      height: 200px;
      background-color: $gradientColor02;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .bg-right {
      height: 100%;
      width: 300px;
      background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
      position: absolute;
      z-index: 1;
      right: 0;
    }
    .badge {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: #00263E;
      position: absolute;
      top: 190px;
      right: calc(300px - 75px);
      z-index: 1;
    }
    .wrapper {
      position: absolute;
      width: calc(100% - 60px);
      width: calc(100% - 60px);
      height: calc(100% - 48px);
      padding: 80px 56px 0;
      background-color: $bgColorWrapper;
      border-radius: 12px;
      .group {
          display: flex;
          align-items: center;
          gap: 32px;
      }
      .description {
          line-height: 1.625;
      }
      .name, .course, .description {
        max-width: 620px;
      }
    }
    .primary {
        color: $primary;
    }
}