.modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0,0.75);
    top: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-content {
        position: relative;
        width: 512px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid var(--app-line-color);
        padding: 36px 48px;
        header h2{
           font-size: 3.2rem;
        }
        p { 
            line-height: 1.7;
            font-size: 1.6rem;
            font-weight: 500;
        }
        footer {
            margin-top: 48px;
        }
        button.btn-close {
            position: absolute;
            top: 8px;
            right: 8px;
            background: none;
        }
    }
}