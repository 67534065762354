.footer {
    border-top: 1px solid #f1f3f5;
    width: 100%;
    padding: 48px 0;
    font-size: 1.6rem;
    margin-top: 96px;
    a {
      text-decoration: none;
    }
    .footer__inner-wrapper {
      max-width: 1280px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      justify-content: space-between;
      padding: 0 28px;
      .left {
        font-weight: 600;
      }
      .right {
        span{
          margin-right: 16px;
        }
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
    }
  }