.home {
    .hero {
        font-size: 3.2rem;
        height: 240px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: -1;
        p {
            margin: 0;
        }
        .hero__inner-wrapper {
            width: 100%;
            height: 100%;
            max-width: 1280px;
            margin: 0 auto;
            background-color: antiquewhite;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('../../assets/home-background.png');
            background-size: cover;
            background-position: center;
            .text-group {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            p:first-child {
                font-size: 2.8rem;
                font-weight: 900;
                text-align: center;
                line-height: 1.3;
                span {
                    color: rgba(126, 98, 235, 0.69);
                }
                margin-bottom: 24px;
            }
            p:last-child {
                font-size: 1.5rem;
                color: #909090;
                font-weight: 600;
                
                span {
                    color: rgba(126, 98, 235, 0.69);
                    font-weight: 600;
                }
            }
        }
    }
    .catalogue {
        padding: 0 24px;
        max-width: 1280px;
        margin: 0 auto;
        .catalogue__heading {
          margin: 24px 0;
          font-size: 2rem;
          font-weight: 700;
        }
        .catalogue__grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
          gap: 28px 40px;
          a {
            text-decoration: none;
          }
          .item {
            background-color: antiquewhite;
            position: relative;
            z-index: -1;
            border: 1px solid #dadce0;
            box-shadow: 0 0 0 0 rgba(60,64,67,.11), 0 2px 6px 2px rgba(60,64,67,.15);
            cursor: pointer;
            background-size: cover;
          }
          .item:before {
            content: "";
            display: block;
            height: 0;
            width: 0;
            padding-bottom: calc(21/29.7 * 100%);
          }
        }
      }
}