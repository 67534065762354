$primary: #ED7575;
$gradientColor01: #FFB5B6;
$gradientColor02: #ED7575;
$bgColorTemplate: #FFFFFF;
.template-15 { 
    width: 100%;
    height: 100%;
    background-color: $bgColorTemplate;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    .left {
      height: 100%;
      width: 300px;
      min-width: 300px;
      max-width: 300px;
      background: linear-gradient(180deg, $gradientColor01 0%, $gradientColor02 100%);
      border: 12px solid white;
      .badge {
        height: 33%;
        z-index: 2;
        position: relative;
      }
    }
    .right {
      height: 100%;
      width: 70%;
      background:none;
      padding:52px;
      align-items: center;
      display: flex;
      .wrapper { 
        .description {
          line-height: 1.625;
        }
      }
    }
    .bg-01 {
      position: absolute;
      top: 12px;
      left: 12px;
      background-image: url('./bg-01.png');
      background-size: cover;
      background-position: top;
      width: 162px;
      height: 300px;
    }
    .bg-02 {
      position: absolute;
      bottom: 0;
      left: -12px;
      background-image: url('./bg-02.png');
      background-size: cover;
      background-position: top;
      width: 300px;
      height: 300px;
    }
}